<template>
  <div class="SportsManagement">
    特殊退款审核
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <span style="height: 40px; line-height: 40px">赛事：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="race"
          ></el-input>
          <span style="height: 40px; line-height: 40px">参赛组别：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="group"
          ></el-input>
          <span style="height: 40px; line-height: 40px">代表队：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="troop"
          ></el-input>
          <span style="height: 40px; line-height: 40px">参赛选手：</span>
          <el-input
            style="padding: 3px 6px; margin-right: 20px; width: 200px"
            v-model="contestant"
          ></el-input>
          <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="findSpecialRefundList"
            >查询</el-button
          >
        </div>
      </div>

      <div class="main-card">
        <el-table :data="tableData" style="width: 100%" height="550">
          <el-table-column label="赛事" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.competitionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛组别" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.groupId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="代表队" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.teamName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参赛选手" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款金额" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.refundMoney / 100 }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.applyTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请原因" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.applyReason }}</span>
            </template>
          </el-table-column>
          <el-table-column label="证明材料" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.pictures }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否已审批" width="100">
            <template slot-scope="scope">
              <span>{{
                scope.row.auditStatus == 0
                  ? "待审批"
                  : scope.row.auditStatus == 1
                  ? "已通过"
                  : "未通过"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审批时间" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.auditTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审批结论" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.auditResult }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审批意见" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.auditIdea }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款进度" width="80">
            <template slot-scope="scope">
              <span>{{
                scope.row.refundStatus == 4
                  ? "退款中"
                  : scope.row.refundStatus == 5
                  ? "退款成功"
                  : "退款失败"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="退款时间" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.refundTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="checkRefund(scope.$index, scope.row, 1)"
                >同意</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handelNo(scope.$index, scope.row, 2)"
                >不同意</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <el-form :model="formModel" label-width="80px">
          <el-form-item label="理由:">
            <el-input type="textarea" v-model="formModel.reason"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm(2)">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formModel: {
        reason: "",
      },
      tableData: [],
      listId: "",
      dialogVisible: false,
      id: "",
      refundStatus: "",
      race: "",
      group: "",
      troop: "",
      contestant: "",
      fileList: [],
      value: "所有",
      value2: true,
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogFormVisible: false,
    };
  },
  methods: {
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.form.name1 = row.name;
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    showDialog() {
      this.dialogFormVisible = false;
    },
    handelNo(index, items) {
      console.log(items.id, "12321321");
      this.listId = items;
      this.dialogVisible = true;
    },
    confirm(items) {
      this.checkRefund("", this.listId, items);
      this.dialogVisible = false;
      this.formModel.reason = "";
    },
    async findSpecialRefundList() {
      try {
        const result = await this.$api.findSpecialRefundList(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_competitionName=${this.race}&search_LIKE_groupName=${this.group}&search_LIKE_teamName=${this.troop}&search_LIKE_userName=${this.contestant}`
        );
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
        console.log(this.tableData);
      } catch (error) {
        console.log(error);
      }
    },
    async checkRefund(index, row, items) {
      try {
        const result = await this.$api.checkRefund(
          `id=${row.id}&refundStatus=${items}&reason=${this.formModel.reason}`
        );
        this.findSpecialRefundList();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.findSpecialRefundList();
  },
};
</script>

<style lang="less">
.SportsManagement {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
      /* switch按钮样式 */
      .switch .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
        top: 0;
        left: 10px;
      }
      /*打开时文字位置设置*/
      .switch .el-switch__label--right {
        z-index: 1;
      }
      /* 调整打开时文字的显示位子 */
      .switch .el-switch__label--right span {
        margin-right: 9px;
      }
      /*关闭时文字位置设置*/
      .switch .el-switch__label--left {
        z-index: 1;
      }
      /* 调整关闭时文字的显示位子 */
      .switch .el-switch__label--left span {
        margin-left: 13px;
      }
      /*显示文字*/
      .switch .el-switch__label.is-active {
        display: block;
      }
      /* 调整按钮的宽度 */
      .switch.el-switch .el-switch__core,
      .el-switch .el-switch__label {
        width: 50px !important;
        margin: 0;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
    width: 600px;
  }
  .el-dialog {
    width: 40%;
  }
}
</style>
